/* reviews.css */
.review-container {
  padding: 20px; /* Increased padding for a bit of spacing */
}

.review-card {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px; /* Reduced margin */
  padding: 10px; /* Reduced padding */
  position: relative; /* Required for positioning the Delete button */
}

.userImage-container {
  margin-right: 10px; /* Spacing between image and content */
}

.userImage-container img {
  width: 60px; /* Adjusted size for compact layout */
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.review-content {
  flex: 1; /* Takes up remaining space */
}

.user-name {
  font-weight: bold;
  font-size: 0.875rem; /* Smaller font size */
  margin-bottom: 4px; /* Reduced margin */
}

.review-comment {
  font-size: 0.875rem; /* Smaller font size */
  margin-bottom: 8px; /* Increased margin for spacing */
}

.star-rating {
  margin-bottom: 10px; /* Increased margin for spacing */
}

.delete-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 0.75rem; /* Smaller button text */
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Increased margin for spacing */
}

.no-reviews {
  text-align: center;
  color: #888;
}
