/* General container styling */
.itinerary-container {
    max-width: 800px;
    margin: 0 auto;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

/* Title styling */
.itinerary-title {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    border: 2px solid #dedcdc; /* Adding an outline border around the text */
    border-radius: 40px; /* Rounded corners for the border */
    padding: 10px 20px; /* Adds space around the text inside the border */
    margin-bottom: 30px; /* Space below the title */
    display: inline-block; /* Makes the border wrap tightly around the text */
    background-color: #f4f7fc; /* Optional: Adds a subtle background to the title */
}

/* Styling for each day item */
.day-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    position: relative;
    padding-left: 80px;
    flex-wrap: wrap; /* Allow items to stack on smaller screens */
}

/* Circle for day number */
.day-circle {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background-color: #3498db;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}

/* Line connecting days */
.day-item:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 25px;
    top: 50px;
    width: 2px;
    height: 100%;
    background-color: #3498db;
}

/* Content box for each day */
.day-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    flex: 1;
    box-shadow: none;
    margin-left: 20px;
    margin-top: 10px;
    max-width: calc(100% - 80px);
    border: 1px solid #ddd;
}
/* Day title inside the content box */
.day-content h3 {
    font-size: 22px;
    font-weight: 600;
    color: #34495e;
    margin-bottom: 12px;
}

/* Description styling */
.day-content p {
    font-size: 14px;
    color: #7f8c8d;
    line-height: 1.6;
}

/* Responsiveness for mobile screens */
@media screen and (max-width: 768px) {
    .itinerary-container {
        padding: 10px;
    }

    .day-item {
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }

    .day-circle {
        position: relative;
        margin-bottom: 15px;
    }

    .day-content {
        width: 100%;
        margin-left: 0;
    }
}
