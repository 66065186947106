/* Header Styles */
.header-travel {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to multiple rows */
  overflow-x: auto; /* Enable horizontal scrolling */
  justify-content: space-around;
  width: 100%; /* Ensure full width of the viewport */
  background-color: #fff; /* Background color for desktop */
}
.header-travel a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the color of the parent element */
}


/* Sticky Header on Mobile */
@media screen and (max-width: 768px) {
  .header-travel {
    position: sticky; /* Make the header sticky on small devices */
    top: 0; /* Stick to the top of the viewport */
    background-color: #fff; /* Ensure visibility while scrolling */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* More prominent shadow */
    z-index: 1000; /* Ensure header is above other content */
    padding: 0; /* Remove any padding if needed */
    margin: 0; /* Remove any margin if needed */
  }
}

/* Scrollbar Styles */
.header-travel::-webkit-scrollbar {
  height: 4px; /* Adjust the scrollbar height */
}

.header-travel::-webkit-scrollbar-thumb {
  background-color: #00b3ff; /* Adjust the scrollbar thumb color */
  border-radius: 6px;
}

.header-travel::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 6px;
}

/* City Styles */
.city {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  margin: 10px; /* Set a uniform margin for consistent spacing */
}

/* Circular Image Styles */
.city .circle {
  border-radius: 50%; /* Ensure circular shape */
  object-fit: cover;
  width: 90px; /* Fixed width */
  height: 80px; /* Fixed height */
}

/* City Name Styles */
.city .city-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center; /* Center align text */
  margin-top: 4px; /* Space between image and text */
}


/* Mobile View Adjustments */
@media screen and (max-width: 600px) {
  .city .circle {
    width: 70px; /* Smaller images for mobile */
    height: 60px;
  }
  .city {
    margin: 8px; /* Adjusted margin for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .city .circle {
    width: 60px; /* Smaller images for very small mobile screens */
    height: 50px;
  }
  .city {
    margin: 6px; /* Adjusted margin for very small screens */
  }
}

@media screen and (max-width: 320px) {
  .city .circle {
    width: 45px; /* Even smaller images for very small screens */
    height: 40px;
  }
  .city {
    margin: 4px; /* Adjusted margin for extremely small screens */
  }
}

/* Responsive Adjustments for Larger Mobile Screens */
@media screen and (min-width: 601px) and (max-width: 900px) {
  .city .circle {
    width: 80px; /* Larger images for larger mobile screens */
    height: 70px;
  }
  .city {
    margin: 10px; /* Consistent margin for larger mobile screens */
  }
}

/* Desktop View Adjustments */
@media screen and (min-width: 1201px) {
  .header-travel {
    flex-wrap: nowrap; /* Ensure header items are in a single row */
    overflow-x: auto; /* Enable horizontal scrolling if needed */
    background-color: #fff; /* Background color for better contrast */
  }

  .city .circle {
    width: 80px; /* Larger images for desktop */
    height: 80px;
  }
  .city {
    margin: 10px; /* Consistent margin for desktop */
  }
}
