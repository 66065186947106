/* General styles */
.form-container {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    max-width: 1200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
}

h2 {
    text-align: center;
    color: #6a1b9a;
    font-weight: normal;
    margin-bottom: 20px;
    font-size: 1.6rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 15px;
}

.form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
}

label {
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 5px;
}

input:focus {
    outline: none;
    border-color: #6a1b9a;
}

.button-group {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin-top: 20px;
}

.submit-button,
.cancel-button {
    padding: 12px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.9rem;
    flex: 1;
    border: none;
}

.submit-button {
    background-color: #6a1b9a;
    color: white;
}

.submit-button:hover {
    background-color: #5a1085;
}

.cancel-button {
    background-color: #f1f1f1;
    color: #6a1b9a;
}

.cancel-button:hover {
    background-color: #e1e1e1;
}

.add-button,
.remove-button {
    background-color: #f1f1f1;
    color: #6a1b9a;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    margin-top: 10px;
    transition: background-color 0.3s, border-color 0.3s;
}

.add-button:hover,
.remove-button:hover {
    background-color: #6a1b9a;
    color: white;
    border-color: #6a1b9a;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .form-container {
        padding: 15px;
    }

    h2 {
        font-size: 1.4rem;
        margin-bottom: 15px;
    }

    .form-row {
        flex-direction: column;
        gap: 15px;
    }

    .button-group {
        flex-direction: column;
        gap: 10px;
    }

    .submit-button,
    .cancel-button,
    .add-button,
    .remove-button {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .form-container {
        padding: 10px;
    }

    h2 {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    .form-row {
        flex-direction: column;
        gap: 10px;
    }

    .submit-button,
    .cancel-button,
    .add-button,
    .remove-button {
        font-size: 1rem;
        padding: 12px;
    }
}

@media (min-width: 1201px) {
    .form-container {
        width: 70%; /* Compact form on large screens */
    }
}

/* Stylish file input for images */
.file-input {
    display: none;
}

.file-label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.file-label:hover {
    background-color: #45a049;
    transform: scale(1.05);
}

.image-upload {
    position: relative;
    display: inline-block;
    margin-bottom: 15px;
}
