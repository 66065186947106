.header {
  background-color: #fff;
}

.header .logo img {
  max-height: 40px; /* Adjust the height as needed */
}

.header .nav-item {
  text-align: center;
}

.header .nav-link {
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.header .nav-item select {
  margin-left: 5px;
  border: none;
  background: transparent;
  font-size: 14px;
}

.header .nav-item small {
  font-size: 12px;
}

.header .dropdown {
  position: relative;
}
/* Vertical line separator */
.header .separator {
  border-left: 1px solid #000000;
  height: 50px;
  margin: 0 1rem;
}
.header .dropdown-menu {
  position: absolute;

  right: 0;
  z-index: 1000;
  display: block;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 14px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175);
}

.header .dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
}

.header .dropdown-item:hover {
  background-color: #f8f9fa;
  color: #000;
}
/* Header.css */

/* Example to ensure logo is smaller on mobile devices */
.header .logo img {
  max-width: 100px;
}

@media (max-width: 767.98px) {
  .header {
    display: none;
  }
  .header .separator {
    display: none;
}
}
