/* Policies.css */


.policies-container {
    padding: 0; /* Remove padding */
    width: 100%; /* Full width */
    background-color: #fff; /* White background for content */
    box-shadow: none; /* Remove box shadow for a flat look */
    margin: 0; /* Remove margins */
}

.policy-card {
    margin-bottom: 10px; /* Reduced margin for cards */
    padding: 10px; /* Keep padding for card */
    border: none; /* Remove border for a clean look */
}

.header {
    text-align: center;
    font-size: 28px; /* Compact header size */
    margin-bottom: 15px; /* Reduced margin */
    color: #333;
    border-bottom: 2px solid #ccc; /* Underline for emphasis */
    padding-bottom: 5px; /* Smaller padding */
}

.policy-card {
    margin-bottom: 15px; /* Reduced margin for cards */
    padding: 10px; /* Reduced padding for card */
    border-bottom: 1px solid #ddd; /* Separation between cards */
}

.card-title {
    font-size: 14px; /* Set card title size */
    color: #0056b3;
    margin-bottom: 5px; /* Reduced margin */
    border-bottom: 1px solid #ddd; /* Line below title */
    padding-bottom: 5px; /* Smaller padding */
}

.sub-title {
    font-size: 14px; /* Set subtitle size */
    margin-top: 10px; /* Reduced margin */
    margin-bottom: 5px; /* Reduced margin */
    color: #0056b3;
    border-bottom: 1px solid #ddd; /* Line below subtitle */
    padding-bottom: 5px; /* Smaller padding */
}

.section {
    display: flex;
    align-items: center; /* Align items vertically centered */
    margin: 5px 0; /* Reduced margin for compactness */
}

.icon {
    font-size: 20px; /* Smaller icon size */
    margin-right: 8px; /* Space between icon and text */
    color: #007bff;
}

.title {
    font-weight: bold;
    font-size: 14px; /* Set title size to 14px */
    margin: 0;
}

.text {
    margin: 0;
    color: #555;
    font-size: 14px; /* More compact text size */
}

.statusIcon {
    font-size: 20px; /* Smaller icon size for status */
    margin-right: 8px; /* Reduced spacing for icons */
}

.allowed {
    color: #28a745; /* Green for allowed */
}

.not-allowed {
    color: #dc3545; /* Red for not allowed */
}

.divider {
    height: 2px;
    background-color: #ddd;
    margin: 20px 0;
}

/* Responsive adjustments */
@media (max-width: 767px) {
    .card-title {
        font-size: 12px; /* Card title for mobile */
    }

    .sub-title {
        font-size: 12px; /* Subtitle for mobile */
    }

    .title {
        font-size: 12px; /* Title for mobile */
    }

    .icon {
        font-size: 18px; /* Smaller icon size for mobile */
    }

    .statusIcon {
        font-size: 18px; /* Smaller icon size for status on mobile */
    }
}

/* Responsive columns for larger screens */
@media (min-width: 768px) {
    .policy-card {
        display: flex;
        flex-direction: column;
    }
    
    .section {
        flex-wrap: wrap; /* Allow sections to wrap */
    }
    
    .section .icon {
        flex-basis: auto; /* Allow the icon to occupy its natural width */
    }
    
    .section div {
        flex-basis: auto; /* Allow text to occupy its natural width */
    }
}
