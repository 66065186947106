.extras {
    margin-top: 5px;
    padding: 10px;
}
.price {
    color: red;
    font-size: 28px; /* Adjust the font size as needed */
    font-weight: bold; /* Optionally make the text bold */
    /* Additional styling properties can be added based on your design requirements */
}

.hotel-name {
    font-size: 24px; /* Adjust the font size as per your design */
    font-weight: bold;
    color: #333; /* Text color */
    margin-bottom: 10px; /* Adjust the margin as needed */
}

.booknow-container {
    box-shadow: 0 4px 6px rgba(221, 132, 132, 0.1);
    border: 1px solid rgb(206, 187, 180);
    padding: 20px; /* Adjust the padding as per your design */
    margin: 20px; /* Adjust the margin as per your design */
    background-color: transparent;
    border-radius: 10px; /* Adjust the border-radius as per your design */
    overflow: hidden; /* Prevents box-shadow clipping with rounded corners */
    max-width: auto;
}

@media screen and (max-width: 767px) {
    #carouselExample {
        max-width: 100%; /* Make the carousel take up the full width */
        max-height: 400px;
        margin-bottom: 10px; /* Add some bottom margin for spacing */
    }

    .carousel-inner img {
        height: 300px;
        max-width: 100%;
    }

    .carousel-control-prev,
    .carousel-control-next {
        display: none; /* Hide carousel navigation controls on mobile */
    }
}

.amenities-container {
    margin-top: 20px;
}

.amenities-container > div {
    margin-bottom: 10px;
}

.amenities-container > div > div {
    padding: 5px 10px;
    display: inline-block;
    margin-right: 10px;
}

.policy-container {
    font-family: 'Arial', sans-serif;
    background-color: transparent;

    padding: 20px;
    border-radius: 8px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.checkIn-policy {
    margin-top: 15px;
    padding-left: 20px;
    border-left: 2px solid #4caf50; /* Green border for Check In Policy */
}

.policy-container p {
    font-weight: bold;
    margin-bottom: 5px;
}

/* Styling for individual policies */
.policy-container p:not(.checkIn-policy) + * {
    margin-bottom: 15px;
}

.custom-col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.custom-d-block {
    display: block;
}

.custom-d-md-none {
    display: none;
}

.custom-booking-details-container {
    position: sticky;
    top: 0;
    width: 90%;
    z-index: 1000;
}

.custom-container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
}

.custom-border {
    border-width: 1px;
    border-style: solid;
}

.custom-p-1 {
    padding: 0.25rem;
}

.custom-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.custom-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.custom-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.custom-col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.custom-form-label {
    margin-bottom: 0.5rem;
}

.custom-form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: transparent;

    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-input-group {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.custom-btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
}

.custom-outlined {
    color: #007bff;
    background-color: transparent;
    border-color: #007bff;
}
/* CSS */
.booking-details-container {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: transparent;

    padding: 10px;
    border: #333;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(82, 81, 81, 0.659);
}
/* Hide booking details container on screens smaller than 992px */
/* @media (max-width: 991px) {
  .booking-details-container {
    display: none;
  }
} */
.extras-container {
    display: flex;
    align-items: flex-start; /* Align items at the top */
}

.description {
    flex: 1; /* Take up remaining space */
    margin-right: 20px; /* Space between description and booking details */
}

.booking-details-container {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0; /* Stick to the top of the viewport */
    width: 300px; /* Adjust width as needed */
    z-index: 1000;
    padding: 20px;
    background-color: transparent;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Hide booking details container on screens smaller than 992px */

.checkIn,
.checkOut {
    margin-bottom: 20px;
}

.selected-cotainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.selected-cotainer > div {
    flex: 1;
}

.rooms-guests {
    margin-top: 20px;
}

.rooms-guests label {
    display: block;
    margin-bottom: 5px;
}

.rooms-guests .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: row; /* Display input fields in a row */
}

/* Adjust width of "Check-in" and "Check-out" input fields */
.rooms-guests .input-group .checkIn input[type='text'],
.rooms-guests .input-group .checkOut input[type='text'] {
    width: 50px;
}

.rooms-guests .input-group input[type='number'] {
    padding: 5px;
    width: 100px; /* Adjust width as needed */
}

.rooms-guests .input-group button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.col-md-12 {
    margin-top: 20px;
}

/* Simplified card styling */
/* .card {
  max-width: 205px;
} */

.card img {
    height: auto;
    max-width: 100%;
}

.card-content {
    padding: 10px;
}

.card-content h5 {
    margin-bottom: 5px;
}

.card-content p {
    margin-bottom: 5px;
}
.date-selection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.checkIn,
.checkOut {
    flex: 1;
    margin-right: 10px; /* Adjust margin as needed */
}

.rooms-guests {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align items to the start */
    margin-top: 20px;
}

.input-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.input-group label {
    margin-right: 10px; /* Adjust margin as needed */
}
.datepicker-input {
    width: 150px;
}
.btn-circle {
    border-radius: 50%;
    padding: 12px; /* Increased padding */
    margin: 0 5px; /* Added margin for spacing */
}

.btn-circle:hover {
    background-color: #f0f0f0;
}

.form-control {
    padding: 6px; /* Increased padding */
}

.form-control:focus {
    border-color: #007bff; /* Added focus border color */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Added focus shadow */
}
.container-fluid {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Align children to the start and end of the container */
    gap: 20px; /* Adjust spacing between the columns */
}

.row {
    display: flex;
    flex: 1; /* Allow row to take up full available space */
}

.rooms {
    flex: 2; /* Adjust this to control the width of the Rooms component */
    padding: 10px; /* Add padding for spacing inside the Rooms component */
    border: 1px solid #ddd; /* Optional: Add a border for visual separation */
}

.booking-details {
    flex: 1; /* Adjust this to control the width of the BookingDetails component */
    padding: 10px; /* Add padding for spacing inside the BookingDetails component */
}
/* Add these styles to your CSS file */
.datepicker-input {
    width: 120px; /* Adjust width as needed */
    font-size: 0.8rem; /* Adjust font size as needed */
}

.check-in p,
.check-out p {
    margin: 0;
    font-size: 0.8rem; /* Adjust font size */
}

.datepicker-container {
    display: flex;
    flex-direction: column;
}
.custom-button {
    background-color: transparent;
    border: 1px solid #d1cdcd; /* Red border for 'error' color */
    color: #000000; /* Red text color */
    font-size: 0.7rem;
    padding: 4px 8px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, color 0.3s;
}

/* Styles.css */

.amenities-and-policies {
    width: 100%; /* Full width for the main container */
    padding: 20px; /* Padding around the main container */
    background-color: transparent; /* White background */
}

.amenities-title {
    font-size: 18px; /* Title size */
    font-weight: bold; /* Bold title */
    color: black; /* Black color for text */
    margin-bottom: 10px; /* Space below the title */
}

.amenities-list {
    display: flex; /* Flexbox for horizontal layout */
    flex-wrap: wrap; /* Allow items to wrap */
    gap: 20px; /* Space between items */
    justify-content: flex-start; /* Align items to the left */
}

.amenity-item {
    display: flex; /* Flexbox for individual amenities */
    align-items: center; /* Center items vertically */
    color: black; /* Black text color */
    flex: 1 1 auto; /* Allow items to grow and shrink as needed */
    min-width: 100px; /* Minimum width for each item */
    margin: 5px; /* Space around each item */
}

.hotel-policies-container {
    width: 100%; /* Full width for accordion */
    margin-top: 20px; /* Space above policies */
}

.accordion {
    width: 100%; /* Full width for accordion */
    background-color: transparent; /* White background */
    box-shadow: none; /* Remove shadow for a flat look */
    border: none; /* No border */
}

.accordion-summary {
    padding: 12px 16px; /* Padding inside summary */
    background-color: transparent; /* Light grey background */
    border-radius: 4px; /* Rounded corners */
    display: flex; /* Flexbox for alignment */
    align-items: center; /* Center content vertically */
    justify-content: space-between; /* Space between items */
}

.accordion-title {
    font-weight: 600; /* Bold title */
    color: black; /* Black color for text */
}

.accordion-details {
    display: flex; /* Flexbox for details */
    flex-direction: column; /* Column layout */
    padding: 16px; /* Padding inside details */

    background-color: transparent; /* White background for details */
}

.additional-amenity {
    display: flex; /* Flexbox for individual amenities */
    align-items: center; /* Center items vertically */
    padding: 8px; /* Padding inside each item */
    border-bottom: 1px solid transparent; /* Line between items */
}

.additional-amenity:last-child {
    border-bottom: none; /* Remove last item's bottom border */
}

@media screen and (min-width: 767px) {
    .amenity-item {
        display: flex; /* Flexbox for individual amenities */
        align-items: center; /* Center items vertically */
        color: black; /* Black text color */
        min-width: 100px; /* Minimum width for each item */
        margin: 5px; /* Space around each item */
        box-sizing: border-box; /* Include padding and border in width calculations */
    }
}
