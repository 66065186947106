/* Disclaimer.css */

/* General modal styling */
.disclaimer-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}

.disclaimer-paper {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 80vh;  /* Limit the height of the modal */
    overflow-y: auto;  /* Allow scrolling if content exceeds height */
    display: flex;
    flex-direction: column; /* Allows stacking of content vertically */
    position: relative; /* Necessary for positioning child elements (sticky buttons) */
}

/* Custom Scrollbar Styling */
.disclaimer-paper::-webkit-scrollbar {
    width: 6px; /* Makes the scrollbar thin */
}

.disclaimer-paper::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Light background for the track */
    border-radius: 10px; /* Rounded corners for the track */
}

.disclaimer-paper::-webkit-scrollbar-thumb {
    background-color: #888; /* Darker color for the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid #f1f1f1; /* Adds a slight border around the thumb for contrast */
}

.disclaimer-paper::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker thumb when hovering */
}

/* Sticky Header Styling */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    position: sticky;      /* Makes the header sticky */
    top: 0;                /* Sticks the header to the top of the modal */
    background-color: white; /* Ensure the background stays white when scrolling */
    z-index: 1;            /* Keeps the header above other content */
    padding-top: 10px;     /* Optional: some space to ensure it doesn't touch the top */
    padding-bottom: 10px;  /* Optional: space for styling */
}

/* Sticky Action Buttons Styling */
.modal-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: auto;      /* Pushes the action buttons to the bottom of the modal */
    padding-top: 20px;
    position: sticky;
    bottom: 0;             /* Makes the buttons stick to the bottom of the modal */
    background-color: white; /* Ensure the background stays white */
    z-index: 1;            /* Keeps the buttons above other content */
    padding-bottom: 10px;  /* Optional: some space around the buttons */
}

/* Close button styling */
.close-button {
    color: #888;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: 0.2s;
}

.close-button:hover {
    color: #000;
}

.modal-header .MuiTypography-h6 {
    font-size: 1.25rem;
    font-weight: bold;
}

.modal-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #eee;
    padding-top: 10px;
    padding-bottom: 10px;
}

.action-button {
    padding: 10px 20px;
    font-size: 1rem;
    text-transform: none;
}

.action-button:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.action-button:first-child {
    background-color: #3f51b5;
    color: white;
}

.action-button:first-child:hover {
    background-color: #303f9f;
}


.accepted-message {
    text-align: center;
    margin-top: 20px;
}

.accepted-message .MuiTypography-body2 {
    font-size: 0.875rem;
    color: #4caf50;
}
