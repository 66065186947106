/* Container for the search bar */
.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 20px auto;
  gap: 10px;
}

/* Input field for search */
.search-input {
  display: flex;
  align-items: center;
  flex: 2;
  gap: 5px;
}

.search-field {
  flex: 1;
}

.location-btn {
  color: #007bff;
  font-size: 24px;
  padding: 5px;
}

/* Date picker fields */
.date-picker {
  flex: 1;
  min-width: 120px;
}

/* Dropdowns for rooms and guests */
.dropdowns {
  flex: 0.8;
  min-width: 100px;
}

/* Search button */
.search-btn {
  flex: 1;
  background-color: #28a745;
  color: #ffffff;
  font-weight: bold;
  min-width: 100px;
}

.search-btn:hover {
  background-color: #218838;
}

/* General spacing for all input fields */
.search-bar .MuiTextField-root {
  width: 100%;
}

.search-bar .MuiOutlinedInput-root {
  height: 40px;
  font-size: 14px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .search-bar {
    flex-wrap: wrap;
    gap: 15px;
  }

  .search-btn {
    width: 100%;
  }

  /* Adjust two fields in a row */
  .search-input,
  .date-picker,
  .dropdowns {
    flex: 1 1 48%;  /* Each input field will take 48% of the width */
  }

  .search-bar {
    gap: 10px; /* Add gap between fields in a row */
  }

  /* For mobile view, keep two fields in a row */
  .search-field, .location-btn {
    flex: 1 1 48%; /* Adjust two fields per row */
  }

  /* Adjust text field height for mobile */
  .search-bar .MuiOutlinedInput-root {
    height: 45px;  /* Increase height for better visibility */
  }

  /* Make the search button 100% width */
  .search-btn {
    width: 100%;
  }
}
