/* Add this CSS to your project's stylesheet */

@media (max-width: 768px) {
    .profile-card {
      width: 100%;
    
    }
  
    .card-body {
      padding: 20px;
    }
  }
  