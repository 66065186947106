/* Hide component by default for larger screens */
.hotel-card {
    display: none;
}

/* Show the component only on mobile devices */
@media (max-width: 700px) {
    .hotel-card {
        display: block;
        overflow: hidden;
        margin: 16px auto;
        max-width: 450px;
    }
}

/* Initial Card Section */
.card-content {
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
    border: 1px solid #f3f3f3; /* Add border here */
    padding: 9px;
}

.image-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.hotel-image {
    width: 120px;
    height: 80px;
    border-radius: 4px;
    object-fit: cover;
}

/* Hotel Information */
.hotel-info {
    flex: 1;
    padding-left: 16px;
}

.hotel-name {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    line-height: 1.4;
}

.hotel-location {
    margin: 4px 0 8px;
    font-size: 14px;
    color: #757575;
    line-height: 1.4;
}

.rating {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.rating span {
    font-size: 14px;
    color: #333;
    margin-left: 4px;
}

.reviews {
    font-size: 12px;
    color: #757575;
    margin-left: 8px;
}

.price-details {
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
}

.current-price {
    font-size: 18px;
    font-weight: bold;
    color: #e53935;
}

.original-price {
    text-decoration: line-through;
    margin-left: 8px;
    font-size: 12px;
    color: #757575;
}

.discount {
    margin-left: 8px;
    font-size: 12px;
    color: #43a047;
}

.tax-info {
    font-size: 12px;
    color: #757575;
    margin-top: 4px;
}

.toggle-button {
    margin-top: 8px;
    background: none;
    border: none;
    color: #1e88e5;
    font-size: 14px;
    cursor: pointer;
}

.toggle-button:hover {
    text-decoration: underline;
}

/* Expanded Details Section */
.expanded-details {
    width: 100%;
    padding: 6px;
    border-top: 1px solid #f9f9f9;
}

.expanded-item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 16px;
}

.room-image {
    width: 115px;
    height: 80px;
    border-radius: 2px;
    object-fit: cover;
}

.expanded-info {
    flex: 1;
}

.room-type {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-bottom: 4px;
}

.price {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 4px;
}

.price .current-price {
    color: #e53935;
    font-weight: bold;
    margin-right: 8px;
}

.price .original-price {
    text-decoration: line-through;
    color: #757575;
    font-size: 12px;
}

.tax-info {
    font-size: 12px;
    color: #757575;
    margin-top: 4px;
}
