/* Container */
.travel-packages {
  display: grid;
  gap: 20px;
  padding: 20px;
  background-color: #ffffff;
  justify-items: center; /* Center cards within grid cells */
  grid-template-columns: repeat(5, minmax(0, 1fr)); /* 5 cards per row by default, flexible */
  grid-template-rows: auto;
  width: 100%;
}

/* Package Card */
.package-card {
  width: 100%; /* Use 100% width of the grid cell, makes it flexible */
  max-width: 350px; /* Ensures the card width does not exceed 350px */
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  position: relative;
}

.package-card:hover {
  transform: scale(1.02);
}

/* Image */
.package-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

/* Package Info */
.package-info {
  padding: 20px;
  text-align: left;
}

/* Title */
.package-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

/* Duration */
.package-duration {
  font-size: 14px;
  color: #777;
  margin-bottom: 15px;
}

/* Nights Badge */
.nights-badge {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  position: absolute;
  top: 150px;
  right: 10px;
}

/* Amenities */
.amenities {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 15px;
}

.amenity {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.amenity svg {
  margin-right: 5px;
  color: #333;
  font-size: 18px;
}

/* Price Section */
.price-section {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.original-price {
  font-size: 14px;
  color: #999;
  text-decoration: line-through;
}

.current-price {
  font-size: 20px;
  font-weight: bold;
  color: #e67e22;
}

/* EMI Information */
.emi-info {
  font-size: 12px;
  color: #666;
  margin-bottom: 15px;
}

/* Button */
.package-button {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #ff5722;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
}

.package-button:hover {
  background-color: #e64a19;
}

/* Responsive Layout for Smaller Screens */

/* Large Screens: Default 5 cards per row */
@media (min-width: 1200px) {
  .travel-packages {
      grid-template-columns: repeat(5, minmax(0, 1fr)); /* 5 cards per row */
  }
}

/* Medium-Large Screens: 4 cards per row */
@media (max-width: 1199px) and (min-width: 992px) {
  .travel-packages {
      grid-template-columns: repeat(4, minmax(0, 1fr)); /* 4 cards per row */
  }
}

/* Medium Screens: 3 cards per row */
@media (max-width: 991px) and (min-width: 768px) {
  .travel-packages {
      grid-template-columns: repeat(3, minmax(0, 1fr)); /* 3 cards per row */
  }
}

/* Small Screens: 2 cards per row */
@media (max-width: 767px) and (min-width: 480px) {
  .travel-packages {
      grid-template-columns: repeat(2, minmax(0, 1fr)); /* 2 cards per row */
  }
}

/* Extra Small Screens: 1 card per row */
@media (max-width: 479px) {
  .travel-packages {
      grid-template-columns: 1fr; /* 1 card per row */
  }
}

/* Mobile View - Adjust for screens smaller than 600px */
@media (max-width: 600px) {
  .package-card {
      width: 100%; /* Card occupies full width of the grid cell */
      max-width: 90%; /* Limit max-width to 90% of screen size */
      margin-bottom: 20px;
  }

  /* Title */
  .package-title {
      font-size: 16px;
  }

  /* Duration */
  .package-duration {
      font-size: 12px;
  }

  /* Nights Badge */
  .nights-badge {
      font-size: 10px;
      padding: 4px 8px;
  }

  /* Amenities Section */
  .amenities {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px 0;
      font-size: 12px;
  }

  .amenity {
      flex: 1 1 30%;
      text-align: center;
      font-size: 10px;
  }

  /* Price Section */
  .price-section {
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
  }

  .original-price {
      font-size: 12px;
  }

  .current-price {
      font-size: 18px;
  }

  /* EMI Information */
  .emi-info {
      font-size: 10px;
  }

  /* Button */
  .package-button {
      font-size: 14px;
      padding: 8px;
  }
}
