/* Container to handle the layout of sidebar and content */
.container {
  display: flex;
  border-radius: 30%;
  height: 60%;
  background-color: #ffffff; /* Light background for the page */
  transition: margin-left 0.3s ease-in-out; /* Smooth transition for content margin */
}

/* Sidebar styles */
.sidebar {
  margin-top: 20px;
  width: 200px;
  height: 60%;
  background-color: #ededed;
  padding: 20px;
  border-radius: 3%;
  color: #333333;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0); /* Default visible position */
  position: absolute; /* Position for toggle button */
  z-index: 1; /* Ensure sidebar is above other content */
}

/* Sidebar hidden styles */
.sidebar.hidden {
  transform: translateX(-100%); /* Move sidebar off-screen */
}

/* Toggle button styles (inside sidebar) */
.toggleButton {
  display: none; /* Hide by default */
  position: absolute; /* Absolute positioning within sidebar */
  top: 20px; /* Space from top */
  right: -40px; /* Position outside sidebar */
  background: #007bff; /* Blue background color */
  color: #ffffff; /* White text color */
  border: none; /* Remove border */
  border-radius: 50%; /* Circular button */
  width: 50px; /* Button width */
  height: 50px; /* Button height */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Pointer cursor */
  font-size: 24px; /* Larger icon size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for button */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

/* Toggle button hover effect */
.toggleButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Icon visible when sidebar is closed */
.openIcon {
  display: none; /* Hide by default */
  position: fixed; /* Fixed positioning outside sidebar */
  top: 20px; /* Space from top */
  left: 20px; /* Space from left */
  background: #007bff; /* Blue background color */
  color: #ffffff; /* White text color */
  border: none; /* Remove border */
  border-radius: 50%; /* Circular icon */
  width: 50px; /* Icon width */
  height: 50px; /* Icon height */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Pointer cursor */
  font-size: 24px; /* Larger icon size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for icon */
  transition: background-color 0.3s ease; /* Smooth background color transition */
  z-index: 1000; /* Ensure icon is on top */
}

/* Icon hover effect */
.openIcon:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Show toggle button and open icon on mobile screens */
@media (max-width: 768px) {
  .toggleButton {
    display: flex; /* Show toggle button */
  }

  .openIcon {
    display: none; /* Hide open icon on mobile */
  }

  .sidebar {
    width: 200px; /* Adjust sidebar width for mobile */
    height: 400px;
  }

  .content {
    margin-left: 0; /* Remove margin for mobile view */
    padding: 20px; /* Adjust padding for mobile view */
  }
}

/* Navigation styles */
.nav {
  list-style: none; /* Remove default list styles */
  padding: 0;
  margin: 0;
}

/* Menu styles */
.menu {
  display: flex;
  flex-direction: column;
}

/* Menu item styles */
.menuItem {
  margin-bottom: 20px; /* Space between menu items */
  text-decoration: none;
}

/* Link styles */
.menuItem a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333333; /* Dark text color */
  font-size: 18px;
  font-weight: 600; /* Semi-bold */
  padding: 12px 10px;
  border-radius: 12px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  position: relative;
}

/* Link hover styles */
.menuItem a:hover {
  background-color: #51a1fc7d; /* Light grey background on hover */
  color: #000000; /* Blue text color on hover */
  transform: translateX(8px); /* Slide link slightly to the right */
}

/* Active link styles */
.menuItem a.active {
  background-color: #007bff; /* Blue background for active link */
  color: #ffffff; /* White text color for active link */
}

/* Adjust content margin when sidebar is hidden */
.container .sidebar.hidden ~ .content {
  flex: 1;
  margin-left: 200px; /* Adjust based on the width of your sidebar */
  transition: margin-left 0.3s; /* Smooth transition for margin */
  margin-left: 0; /* Remove margin when sidebar is closed */
}

/* Styles when the sidebar is visible */
.container .sidebar:not(.hidden) ~ .content {
  flex: 1;
  margin-left: 220px; /* Adjust based on the width of your sidebar */
  transition: margin-left 0.3s; /* Smooth transition for margin */
}
