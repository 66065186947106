.review-container {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;

}

.review-card {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
}

.review-card-content {
  flex: 1;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.user-name {
  font-weight: 600;

  margin-right: 10px;
}

.review-date {
  font-size: 0.85rem;
}

.review-comment {
  font-size: 1rem;

  margin-bottom: 10px;
  line-height: 1.5;
}

.star-rating {
  display: flex;
}

.review-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.review-card {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
}

.userImage-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;

}

.userImage-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.review-content {
  flex: 1;
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.user-name {
  font-weight: bold;
}

.review-date {
  font-size: 0.85rem;
}

.review-comment {
  font-size: 1rem;

  margin-bottom: 10px;
  line-height: 1.5;
}

.star-rating {
  display: flex;
}

.delete-button {
  margin-top: 10px;
}

.no-reviews {
  text-align: center;
  font-style: italic;

  margin-top: 20px;
}
